import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';

import { Slot } from '@radix-ui/react-slot';
import { cn } from '@web/utilities/styles';

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap font-medium ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 dark:ring-offset-slate-950 dark:focus-visible:ring-slate-300',
  {
    variants: {
      variant: {
        default: 'bg-gradient-to-r from-[#8B5CF6] to-[#5B21B6] text-white',
        destructive:
          'bg-red-500 text-slate-50 hover:bg-red-500/90 dark:bg-red-900 dark:text-slate-50 dark:hover:bg-red-900/90',
        outline:
          'border border-violet-500 bg-white hover:bg-slate-100 hover:text-slate-900 dark:border-slate-800 dark:bg-slate-950 dark:hover:bg-slate-800 dark:hover:text-slate-50',
        secondary:
          'bg-slate-100 text-slate-900 hover:bg-slate-100/80 dark:bg-slate-800 dark:text-slate-50 dark:hover:bg-slate-800/80',
        ghost:
          'hover:bg-slate-100 hover:text-slate-900 dark:hover:bg-slate-800 dark:hover:text-slate-50',
        gradient: 'bg-gradient-to-r from-purple-500 to-purple-700 text-white',
        link: 'text-slate-900 underline-offset-4 hover:underline dark:text-slate-50',
        destructiveLink:
          'text-red-500 underline-offset-4 hover:underline dark:text-red-500',
        transparentOutline:
          'border border-purple-500 bg-transparent text-white hover:bg-purple-500 hover:text-white',
        landing:
          'flex items-center justify-center gap-2 rounded-lg bg-violet-500 px-6 py-4 text-center text-sm font-semibold text-white'
      },
      size: {
        default: 'rounded-xl px-6 py-1',
        sm: 'h-9 rounded-2xl px-3',
        lg: 'rounded-2xl px-8 py-2',
        icon: 'size-10',
        flat: 'w-[352px] gap-2 rounded-xl px-6 py-1'
      }
    },
    defaultVariants: {
      variant: 'default',
      size: 'default'
    }
  }
);

type ButtonVariantProps = VariantProps<typeof buttonVariants>;

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  asChild?: boolean;
  variant?: ButtonVariantProps['variant'];
  size?: ButtonVariantProps['size'];
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={cn(
          buttonVariants({ variant, size, className }),
          'font-inter text-sm font-medium leading-8'
        )}
        ref={ref}
        {...props}
      />
    );
  }
);
Button.displayName = 'Button';

export { Button, buttonVariants };
